<template>
    <div>
        <div>
            <span class="scene">场景：</span>
            <div class="i_block">
                <el-select class="m_r1" size="small" clearable @clear="clearableMethod" @change="selectChanged"
                           v-model="addForm.sceneId" placeholder="请选择场景">
                    <el-option
                            v-for="item in sceneOpt"
                            :key="item.id"
                            :label="item.sceneName"
                            :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <el-dropdown split-button type="primary" @click="init('srh')" size="small">高级筛选
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="init('add')"><span class="el-icon-circle-plus p_r1"></span>新增场景
                    </el-dropdown-item>
                    <el-dropdown-item @click.native="sceneVisible = true"><span class="el-icon-s-tools p_r1"></span>管理
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <el-dialog :title="title" :visible.sync="visible" :close-on-click-modal="false"
                   :close-on-press-escape="false"
                   :modal-append-to-body="true"
                   :append-to-body="true" :before-close="close" width="55%">
            <el-form size="small" ref="addForm" :model="addForm" label-width="0px">
                <el-row :gutter="20">
                    <el-col :span="7">
                        <el-form-item v-if="method !== 'srh'" prop="sceneName"
                                      :rules="[{required: true, whitespace: true, message: '场景内容不能为空', trigger: 'blur'}]">
                            <div class="m_b1">场景名称</div>
                            <el-input size="small" v-model.trim="addForm.sceneName" placeholder="请输入场景名称"
                                      clearable
                                      maxlength="10"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="m_b1">筛选条件</div>
                <el-row :gutter="20" v-for="(item, index) in addForm.queryConditions" :key="index">
                    <el-col :span="7">
                        <el-form-item :prop="`queryConditions.${index}.originalId`"
                                      :rules="[{required: true, whitespace: true, message: '筛选的字段名不能为空', trigger: 'blur'}]">
                            <el-select v-model="item.originalId" placeholder="请选择要筛选的字段名" size="small"
                                       style="width: 100%" @change="changeFieldName(item)">
                                <el-option
                                        v-for="item in fieldNameOpt2"
                                        :key="item.fieldId"
                                        :label="item.fieldName"
                                        :value="item.fieldId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="7">
                        <!-- :disabled="onSelectUser" -->
                        <el-select v-model="item.queryCondition"
                                   placeholder="请选择" size="small" style="width: 100%">
                            <el-option
                                    v-for="itemC in equalOpt"
                                    :key="itemC.id"
                                    :label="itemC.label"
                                    :value="itemC.id">
                            </el-option>
                        </el-select>
                    </el-col>

                    <!-- 查询值 -->
                    <el-col :span="7" v-if="item.queryCondition != 8 && item.queryCondition != 9">
                        <!-- 0输入框 -->
                        <el-form-item v-if="item.detailsShowType==0" :prop="`queryConditions.${index}.queryValue`"
                                      :rules="[{required: true, whitespace: true, message: '筛选条件不能为空', trigger: 'blur'}]">
                            <el-input v-model="item.queryValue" placeholder="请输入筛选值" size="small"></el-input>
                        </el-form-item>

                        <!-- 6 用户框 -->
                        <el-form-item v-if="item.detailsShowType==6" :prop="`queryConditions.${index}.queryValue`"
                                      :rules="[{required: true, whitespace: true, message: '筛选条件不能为空', trigger: 'blur'}]">
                            <el-select
                                    style="width: 100%"
                                    v-model="item.queryValue"
                                    placeholder="请选择姓名"
                                    size="small"
                                    filterable
                                    remote
                                    :remote-method="getUserByNameList"
                                    :loading="getNameLoading">
                                <el-option
                                        v-for="item in nameList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <!-- 1下拉选择框 -->
                        <el-form-item v-if="item.detailsShowType==1" :prop="`queryConditions.${index}.queryValue`"
                                      :rules="[{required: true, whitespace: true, message: '筛选条件不能为空', trigger: 'blur'}]">
                            <el-select
                                    v-model="item.queryValue"
                                    placeholder="请选择"
                                    size="small"
                                    filterable
                                    remote
                            >
                                <el-option
                                        v-for="obj in $dictUtils.getDictList(item.dictType)"
                                        :key="obj.value"
                                        :label="obj.label"
                                        :value="obj.value">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <!-- 2年度选择框 -->
                        <el-form-item v-if="item.detailsShowType==2" :prop="`queryConditions.${index}.queryValue`"
                                      :rules="[{required: true, whitespace: true, message: '筛选条件不能为空', trigger: 'blur'}]">
                            <div class="block">
                                <span class="demonstration"></span>
                                <el-date-picker
                                        v-model="item.queryValue"
                                        type="year"
                                        size="small"
                                        value-format="yyyy"
                                        placeholder="选择年">
                                </el-date-picker>
                            </div>
                        </el-form-item>

                        <!-- 3日期选择框 -->
                        <el-form-item v-if="item.detailsShowType==3" :prop="`queryConditions.${index}.queryValue`"
                                      :rules="[{required: true, whitespace: true, message: '筛选条件不能为空', trigger: 'blur'}]">
                            <div class="block">
                                <span class="demonstration"></span>
                                <el-date-picker
                                        v-model="item.queryValue"
                                        value-format="yyyy-MM-dd"
                                        type="date"
                                        size="small"
                                        placeholder="选择日期">
                                </el-date-picker>
                            </div>
                        </el-form-item>

                        <!-- 4switch -->
                        <el-form-item v-if="item.detailsShowType==4" :prop="`queryConditions.${index}.queryValue`"
                                      :rules="[{required: true, whitespace: true, message: '筛选条件不能为空', trigger: 'blur'}]">
                            <el-switch
                                    v-model="item.queryValu"
                                    active-color="#13ce66"
                                    size="small"
                                    inactive-color="#ff4949">

                            </el-switch>
                        </el-form-item>

                        <!-- 5日期选择框 -->
                        <el-form-item v-if="item.detailsShowType==5" :prop="`queryConditions.${index}.queryValue`"
                                      :rules="[{required: true, whitespace: true, message: '筛选条件不能为空', trigger: 'blur'}]">
                            <div class="block">
                                <span class="demonstration"></span>
                                <el-date-picker
                                        size="small"
                                        v-model="item.queryValue"
                                        value-format="yyyy-MM-dd"
                                        type="date"
                                        placeholder="选择日期时间">
                                </el-date-picker>
                            </div>
                        </el-form-item>

                        <!-- 7日期时间选择框 -->
                        <el-form-item v-if="item.detailsShowType==7" :prop="`queryConditions.${index}.queryValue`"
                                      :rules="[{required: true, whitespace: true, message: '筛选条件不能为空', trigger: 'blur'}]">
                            <div class="block">
                                <span class="demonstration"></span>
                                <el-date-picker
                                    size="small"
                                    v-model="item.queryValue"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    type="datetime"
                                    placeholder="选择日期时间">
                                </el-date-picker>
                            </div>
                        </el-form-item>

                    </el-col>

                    <el-col :span="1">
                        <div class="el-icon-error" @click="del(index, 1)"></div>
                    </el-col>
                </el-row>
            </el-form>
            <div class="m_t1">
                <el-button type="text" class="addCondition" @click="addCondition">+ 添加筛选条件</el-button>
                <div class="saveScene" v-if="method === 'srh'">
                    <el-checkbox v-model="saveScene">保存为场景</el-checkbox>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="close">取消</el-button>
                <el-button size="small" type="primary" @click="confirm()" v-noMoreClick>确定</el-button>
            </span>
        </el-dialog>
        <!--场景管理-->
        <el-dialog title="场景管理"  :close-on-press-escape="false"
                   :modal-append-to-body="true" :append-to-body="true" :visible.sync="sceneVisible" width="50%">
            <div class="manageTags">您可通过拖拽管理标签</div>
            <div class="manageTagsBox">
                <div class="manageTagsHead flex_b_c">
                    <div>
                        <el-checkbox @change="displayFieldNameOpt()" v-model="manageTags"></el-checkbox>
                        <span class="m_l1">显示的标签</span>
                    </div>
                    <div class="undertint">{{selectList.length}}/{{fieldNameOpt.length}}</div>
                </div>
                <draggable class="draggableBox" v-model="fieldNameOpt" element="tbody" :move="getdata"
                           @update="datadragEnd">
                    <div v-for="(item,index) in fieldNameOpt" :key="index" class="tagsItem flex_b_c">
                        <div>
                            <el-checkbox v-model="item.delFlag" :checked="item.delFlag==0? true:false" :true-label="0"
                                         :false-label="1" @change="handleCheckAllChange(item)"></el-checkbox>
                            <span class="m_l1">{{item.sceneName}}</span>


                        </div>
                        <div>
                            <i class="el-icon-edit undertint" @click.stop.prevent="init('edit',item)"></i>
                            <i class="el-icon-delete m_l1 undertint" @click.stop.prevent="del(item,2)"></i>
                        </div>
                    </div>
                </draggable>
            </div>
            <el-button type="text" class="addCondition" @click="init('add')">+ 新增场景</el-button>
            <span slot="footer" class="dialog-footer">
                <!-- <el-button size="small" @click="sceneVisible = false">取消</el-button> -->
                <!-- <el-button size="small" type="primary" @click="confirmManageTags()" v-noMoreClick>保存</el-button> -->
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import draggable from "vuedraggable";

    export default {
        components: {draggable},
        props: {
            moduleId: String,
        },
        data() {
            return {
                scene: '',
                sceneOpt: [],
                visible: false,
                title: '',
                method: '',
                // 场景
                addForm: {
                    sceneName: '',
                    sceneId: '',
                    queryConditions: [
                        {
                            originalId: '',
                            queryValue: '',
                            equalOpt: [
                                // {
                                //     id: '0',
                                //     label: '等于',
                                //     enabled: true
                                // },
                                // {
                                //     id: '1',
                                //     label: '大于',
                                //     enabled: true
                                // },
                                // {
                                //     id: '2',
                                //     label: '小于',
                                //     enabled: true
                                // }
                            ],
                        }
                    ],
                },
                equalOpt: [
                    {
                        id: '0',
                        label: '等于',
                        enabled: true
                    }
                ],
                // 筛选字段选项
                fieldNameOpt: [],
                selectList: [],
                temfieldNameOpt: [],
                selectFieldNameOpt: 0,
                fieldNameOpt2: [],
                saveScene: false,
                // 场景管理
                sceneVisible: false,
                manageTags: false,
                fieldNameFind: '',
                getNameLoading: false,
                nameList: [],
            }
        },
        mounted() {
            this.getMySceneList()
            this.getSceneList()
            this.getColumnList()
            this.getQueryExs()
            //this.clearableMethod()
        },
        methods: {
            init(method, row) {
                this.visible = true;
                this.method = method;
                if (method === 'add') {
                    this.title = `新增场景`
                } else if (method === 'srh') {
                    this.saveScene = false
                    this.title = '高级筛选'
                } else if (method === 'edit') {
                    this.title = '编辑场景'
                }
                this.$nextTick(() => {
                    this.$refs.addForm.resetFields()
                    this.addForm.queryConditions = [{
                        originalId: '',
                        queryValue: '',
                        equalOpt: [],
                    }]

                    if (method === 'edit') {
                        this.addForm.sceneName = row.sceneName
                        this.addForm.sceneId = row.id;
                        var that = this;
                        that.$axios(this.api.sys.getConditionById + row.id, '', "get").then((res) => {
                            if (res.status) {
                                this.addForm.queryConditions = res.data
                                this.addForm.queryConditions.map(item => {
                                    this.fieldNameOpt2.map(item2 => {
                                        if (item.originalId == item2.fieldId) {
                                            this.changeFieldName(item, 2)
                                        }
                                    })
                                })
                                if (this.addForm.queryConditions != null && this.addForm.queryConditions.length > 0) {
                                    this.addForm.queryConditions.map(item => {
                                        if (item.fieldEname === "update_user" || item.fieldEname === "create_user") {
                                            this.$set(item, "onSelectUser", true)
                                        } else {
                                            this.$set(item, "onSelectUser", false)
                                        }
                                        if (item.detailsShowType == 6) {
                                            this.getUserByNameList("", item.queryValue)
                                            if (this.nameList != null && this.nameList.length > 0) {
                                                item.queryValue = this.nameList[0].name
                                            }
                                        }
                                    })
                                }
                            }
                        })

                    }
                })
            },
            close() {
                // CL 取消保留场景选项
                this.addForm.sceneName = ''
                this.addForm.sceneId = ''
                this.addForm.queryConditions = []
                this.visible = false
                this.nameList = []
            },
            getUserByNameList(name, userId) {
                if ((name != null && name.trim() != '') || (userId != null && userId.trim() != '')) {
                    userId = userId == null ? 0 : userId;
                    this.$axios(this.api.auth.getSysUserByName + "?userName=" + name + "&userId=" + userId, {}, "get").then((res) => {
                        if (res.status) {
                            this.nameList = res.data
                        }
                    })
                }
            },
            // 高级查询字段
            getColumnList() {
                var that = this;
                that.$axios(this.api.sys.getHighQueryFields + this.moduleId, '', "get").then((res) => {
                    if (res.status) {
                        this.fieldNameOpt2 = res.data
                    }
                })
            },
            // 条件判断
            getQueryExs() {
                var that = this;
                that.$axios(this.api.sys.getQueryExsY, '', "get").then((res) => {
                    if (res.status) {
                        // this.equalOpt = res.data
                        this.conditionMap = res.data
                    }
                })
            },


            // 筛选字段名改变
            changeFieldName(data, num) {
                if (!num) {
                    data.queryValue = null
                }
                this.fieldNameOpt2.map(item => {
                    if (data.originalId == item.fieldId) {
                        this.fieldNameFind = item.fieldName
                        var valType = item.valType
                        data.detailsShowType = item.detailsShowType
                        data.dictType = item.dictType
                        if (item.fieldEname === "update_user" || item.fieldEname === "create_user") {
                            this.$set(data, "onSelectUser", true)
                        } else {
                            this.$set(data, "onSelectUser", false)
                        }
                        this.setCondition(valType)
                    }
                })
                if(data.queryCondition == null || data.queryCondition == ''){
                    this.$set(data, "queryCondition", this.equalOpt[0].id)
                }
            },

            setCondition(valType) {
                this.equalOpt = this.conditionMap[valType]
            },
            // 获取我的场景
            getMySceneList() {
                var that = this;
                var param = {
                    pageId: this.moduleId,
                    state: 0  //显示
                }
                that.$axios(this.api.sys.sceneList, param, "get").then((res) => {
                    if (res.status) {
                        that.sceneOpt = res.data;
                    }
                })
            },

            handleCheckAllChange(val) {
                var that = this;
                let parma = {
                    sceneId: val.id,
                    state: val.delFlag
                }
                that.$axios(this.api.sys.sceneShowOrHide, parma, "get").then((res) => {
                    if (res.status) {
                        that.getSceneList()
                        that.getMySceneList()
                    }
                })
            },

            // 获取我的管理场景列表
            getSceneList() {
                var that = this;
                var param = {
                    pageId: this.moduleId,
                    state: 1  //所有
                }
                that.$axios(this.api.sys.sceneList, param, "get").then((res) => {
                    if (res.status) {
                        that.fieldNameOpt = res.data;
                        that.temfieldNameOpt = res.data;
                        var newarr = new Array()
                        var array = that.fieldNameOpt
                        for (let index = 0; index < array.length; index++) {
                            const element = array[index];
                            if (element.delFlag === true || element.delFlag === 0) {
                                newarr.push(element)
                            }
                        }
                        this.selectList = newarr
                    }
                })
            },
            // 显示的标签
            displayFieldNameOpt() {
                if (this.manageTags) {
                    var newarr = new Array()
                    var array = this.fieldNameOpt
                    for (let index = 0; index < array.length; index++) {
                        const element = array[index];
                        if (element.delFlag === true || element.delFlag === 0) {
                            newarr.push(element)
                        }
                    }
                    this.selectList = newarr
                    this.fieldNameOpt = newarr
                } else {
                    this.fieldNameOpt = this.temfieldNameOpt
                }
            },
            // 删除筛选条件
            del(index, type) {
                if (type == 1 && this.addForm.queryConditions.length == 1) {
                    this.$message.warning("至少保留一条搜索项！")
                    return
                }
                var that = this;
                this.$confirm(`您确定要删除这一条数据吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    if (type == 1) {
                        this.addForm.queryConditions.splice(index, 1)
                    } else if (type == 2) {
                        that.$axios(this.api.sys.sceneRemoveById + index.id, "", "get").then((res) => {
                            if (res.status) {
                                this.$message.success("删除成功")
                                that.getSceneList()
                                that.getMySceneList()
                            } else {
                                this.$message.error("删除失败")
                            }
                        })
                    }
                })
            },
            // 添加筛选条件
            addCondition() {
                this.addForm.queryConditions.push({
                    originalId: '',
                    queryValue: '',
                    onSelectUser: false,
                    equalOpt: [],
                })
            },
            confirm() {
                var that = this;
                this.$refs['addForm'].validate((valid) => {
                        //资源类型设置
                        if (that.fieldNameFind == "资源类型" && that.addForm.queryConditions[0].queryValue != null) {
                            if (that.addForm.queryConditions[0].queryValue.indexOf("三维") != -1) {
                                that.addForm.queryConditions[0].queryValue = 0
                            } else if (that.addForm.queryConditions[0].queryValue.indexOf("图片") != -1) {
                                that.addForm.queryConditions[0].queryValue = 1
                            } else if (that.addForm.queryConditions[0].queryValue.indexOf("音频") != -1) {
                                that.addForm.queryConditions[0].queryValue = 2
                            } else if (that.addForm.queryConditions[0].queryValue.indexOf("视频") != -1) {
                                that.addForm.queryConditions[0].queryValue = 3
                            } else if (that.addForm.queryConditions[0].queryValue.indexOf("出版物") != -1) {
                                that.addForm.queryConditions[0].queryValue = 4
                            } else if (that.addForm.queryConditions[0].queryValue.indexOf("文献") != -1) {
                                that.addForm.queryConditions[0].queryValue = 5
                            } else if (that.addForm.queryConditions[0].queryValue.indexOf("文件") != -1) {
                                that.addForm.queryConditions[0].queryValue = 6
                            } else if (that.addForm.queryConditions[0].queryValue.indexOf("其他") != -1) {
                                that.addForm.queryConditions[0].queryValue = 7
                            }
                        }
                        if (that.fieldNameFind == "是否藏品" && that.addForm.queryConditions[0].queryValue != null) {
                            if (that.addForm.queryConditions[0].queryValue.indexOf("否") != -1) {
                                that.addForm.queryConditions[0].queryValue = 0
                            } else if (that.addForm.queryConditions[0].queryValue.indexOf("是") != -1) {
                                that.addForm.queryConditions[0].queryValue = 1
                            }
                        }
                        if (that.fieldNameFind == "资源状态" && that.addForm.queryConditions[0].queryValue != null) {
                            if (that.addForm.queryConditions[0].queryValue.indexOf("采集") != -1) {
                                that.addForm.queryConditions[0].queryValue = 0
                            } else if (that.addForm.queryConditions[0].queryValue.indexOf("编目") != -1) {
                                that.addForm.queryConditions[0].queryValue = 1
                            } else if (that.addForm.queryConditions[0].queryValue.indexOf("鉴定") != -1) {
                                that.addForm.queryConditions[0].queryValue = 2
                            } else if (that.addForm.queryConditions[0].queryValue.indexOf("开放") != -1) {
                                that.addForm.queryConditions[0].queryValue = 3
                            } else if (that.addForm.queryConditions[0].queryValue.indexOf("受控") != -1) {
                                that.addForm.queryConditions[0].queryValue = 4
                            } else if (that.addForm.queryConditions[0].queryValue.indexOf("回退") != -1) {
                                that.addForm.queryConditions[0].queryValue = 5
                            }
                        }
                        if (that.fieldNameFind == "检测状态" && that.addForm.queryConditions[0].queryValue != null) {
                            if (that.addForm.queryConditions[0].queryValue.indexOf("通过") != -1) {
                                that.addForm.queryConditions[0].queryValue = 0
                            } else if (that.addForm.queryConditions[0].queryValue.indexOf("异常") != -1) {
                                that.addForm.queryConditions[0].queryValue = 1
                            } else if (that.addForm.queryConditions[0].queryValue.indexOf("未检测") != -1) {
                                that.addForm.queryConditions[0].queryValue = null
                                that.addForm.queryConditions[0].queryCondition = 8
                            }
                        }
                        if (valid) {
                            this.visible = false;
                            this.addForm.pageId = that.moduleId
                            if (this.method === 'add') {
                                this.addForm.sceneId = ''
                                that.$axios(this.api.sys.sceneSave, JSON.stringify(that.addForm), "post").then((res) => {
                                    if (res.status) {
                                        this.$message.success(res.msg)
                                        that.getSceneList()
                                        that.getMySceneList()
                                    }
                                })
                            } else if (this.method === 'srh') {
                                this.title = '高级筛选'
                                this.addForm.sceneId = ''
                                if (this.saveScene) {
                                    that.$axios(this.api.sys.sceneSave, JSON.stringify(that.addForm), "post").then((res) => {
                                        if (res.status) {
                                            that.getSceneList()
                                            that.getMySceneList()
                                        }
                                    })
                                }
                                //this.$emit('getDataList', this.addForm)
                                this.$emit('getDataList', JSON.parse(JSON.stringify(this.addForm)))
                            } else if (this.method === 'edit') {
                                //this.addForm.sceneId =''
                                that.$axios(this.api.sys.sceneUpdateById, JSON.stringify(that.addForm), "post").then((res) => {
                                    if (res.status) {
                                        this.$message.success(res.msg)
                                        that.getSceneList()
                                        that.getMySceneList()
                                        this.addForm.sceneId = ''
                                    }
                                })
                            }
                            this.close()
                        }

                    }
                )
            },
            selectChanged() {
                if (this.addForm.sceneId == '') {
                    return
                }
                this.$set(this.addForm, "clearCurrent", false)
                this.$emit('getDataList', this.addForm)
            },
            // 清空场景调用
            clearableMethod() {
                this.addForm.queryConditions = []
                this.$set(this.addForm, "clearCurrent", true)
                this.$emit('getDataList', this.addForm)
            },
            // 设置拖动排序
            getdata(evt) {
                //console.log(evt);
                // console.log(this.fieldNameOpt,"---");
            },
            datadragEnd(evt) {
            },
            delTags() {

            },
            // 保存场景管理
            confirmManageTags() {

            },
        },
    }
</script>

<style scoped>
    .scene, .saveScene >>> .el-checkbox__label, .addCondition.el-button {
        font-size: 13px !important;
    }

    .scene, .saveScene >>> .el-checkbox__label {
        color: #333;
    }

    .el-icon-error {
        color: #bbb;
        cursor: pointer;
        margin-top: 10px;
    }

    .manageTags {
        font-size: 12px;
        padding-bottom: 5px;
        color: #aaa;
    }

    .manageTagsBox {
        border: 1px solid #e6e6e6;
        border-radius: 2px;
        padding: 15px;
    }

    .manageTagsHead {
        padding-bottom: 15px;
        padding-left: 9px;
        border-bottom: 1px dashed #e6e6e6;
        font-size: 13px;
    }

    .draggableBox {
        display: block;
        width: 100%;
        height: 250px;
        overflow-y: auto;
    }

    .tagsItem {
        padding-top: 8px;
        padding-left: 9px;
        font-size: 13px;
        color: #333;
        cursor: pointer;
    }

    .undertint {
        color: #aaa;
    }
</style>
