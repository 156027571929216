<template>
    <!--表格列表拖拽排序弹窗-->
    <el-dialog title="自定义列表项" :visible.sync="setShow" width="60%" :before-close="handleClose">
        <div>
            <div class="customList">
                <!--main-->
                <table>
                    <thead>
                    <tr class="b_b">
                        <td class="f_w">显示</td>
                        <td class="f_w">列名</td>
                        <td class="f_w">拖动调整顺序</td>
                    </tr>
                    </thead>
                    <draggable v-model="tablelist" element="tbody" :move="getdata" @update="datadragEnd">
                        <tr v-for="(item,index) in tablelist" :key="index" class="b_b">
                            <td>
                                <el-checkbox v-model="item.logicType"></el-checkbox>
                            </td>
                            <td>{{ item.fieldName }}</td>
                            <td><img src="../../assets/img/icon_move.png"></td>
                        </tr>
                    </draggable>
                </table>
            </div>
            <div class="flex_b_c">
                <div class="blue pointer" v-on:click="updateDefault()">恢复默认</div>
                <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="robackColunm()">取 消</el-button>
          <el-button size="small" type="primary" v-no-more-click @click="commitColunm()">确 定</el-button>
        </span>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    import draggable from "vuedraggable";

    export default {
        name: "draggablePop",
        components: {draggable},
        props: {
            moduleId: String,
            setShow: Boolean,
            //有这个字段就是专题库的列表排序
            fieldType: Number, //专题库类型（0我的专题库，1公共专题库，2媒体报道专题库）
        },
        data() {
            return {
                tablelist: [],   // 列表每列名称数据
                defaultTablelist: [],
            }
        },
        mounted() {
            // 页面加载调用方法
            this.getColumnList();
        },
        methods: {
            // 请求列表数据
            getColumnList() {
                if (this.fieldType == 0 || this.fieldType) {
                    this.$axios(this.api.original.thematiclibraryfieldList, {
                        fieldType: this.fieldType,
                    }, 'get').then((res) => {
                        if (res.status) {
                            let tableCommList = []
                            this.tablelist = res.data.map(item => {
                                if (item.onShow === 0) {
                                    this.$set(item, 'logicType', true)
                                    tableCommList.push(item)
                                } else {
                                    this.$set(item, 'logicType', false)
                                }
                                return item
                            })
                            this.$emit('getTbList', tableCommList, this.setShow);
                        }
                    })
                } else {
                    this.$axios(this.api.sys.getByOriginalId + this.moduleId, '', "get").then((res) => {
                        if (res.status) {
                            let tableCommList = []
                            this.tablelist = res.data.map(item => {
                                if (item.logicType === 0) {
                                    this.$set(item, 'logicType', true)
                                    tableCommList.push(item)
                                } else {
                                    this.$set(item, 'logicType', false)
                                }
                                return item
                            })
                            this.$emit('getTbList', tableCommList, this.setShow);
                        }
                    })
                }
            },

            // 确认修改排序列
            commitColunm() {
                var that = this;
                if (this.fieldType == 0 || this.fieldType) {
                    let dataList = this.tablelist.map((item, index) => {
                        item.fieldSort = index
                        item.onShow = item.logicType == true ? 0 : 1
                        return item
                    })
                    let hide = dataList.filter(item => {
                        return item.onShow == 0
                    })
                    if (hide.length < 5) {
                        this.$message.warning("勾选项不能少于等于五项!")
                        return
                    }
                    this.$axios(this.api.original.thematiclibraryfieldSave, dataList, 'post').then((res) => {
                        if (res.status) {
                            that.getColumnList()
                            that.$emit('getTbList', that.tablelist, false);
                            this.$message.success(res.msg)
                        } else {
                            this.$message(res.msg)
                        }
                    })
                } else {
                    let hide = 0;
                    let obj = this.tablelist.map((item, index) => {
                        item.orderNum = index;
                        if (item.logicType == true) {
                            item.logicType = 0
                            hide++;
                        } else {
                            item.logicType = 1
                        }
                        return item
                    })
                    if (obj.length >= 5 && hide < 6) {
                        this.getColumnList()
                        this.$message.warning("勾选项不能少于等于五项!")
                    } else {
                        var param = {
                            originalId: this.moduleId,
                            sysModuleFields: obj
                        }
                        this.$axios(this.api.sys.saveModuleField, param, "post").then((res) => {
                            if (res.status) {
                                that.getColumnList()
                                that.$emit('getTbList', that.tablelist, false);
                                this.$message.success(res.msg)
                            } else {
                                this.$message(res.msg)
                            }
                        })
                    }
                }
            },
            // 取消修改排序列
            robackColunm() {
                this.getColumnList();
                this.$emit('getTbList', this.tablelist, false);
            },
            //恢复默认修改排序列
            updateDefault() {
                if (this.fieldType == 0 || this.fieldType) {
                    this.$axios(this.api.original.restoreDefault, {
                        fieldType: this.fieldType,
                    }, 'get').then((res) => {
                        if (res.status) {
                            console.log(res)
                            let tableCommList = []
                            this.tablelist = res.data.map(item => {
                                if (item.onShow === 0) {
                                    this.$set(item, 'logicType', true)
                                    tableCommList.push(item)
                                } else {
                                    this.$set(item, 'logicType', false)
                                }
                                return item
                            })
                            this.$emit('getTbList', tableCommList, this.setShow);
                        }
                    })
                } else {
                    this.$axios(this.api.sys.getDefaultById + this.moduleId, '', "get").then((res) => {
                        if (res.status) {
                            for (let i = 0; i < res.data.length; i++) {
                                res.data[i].logicType = res.data[i].logicType === 0 ? true : false;
                            }
                            this.tablelist = res.data;
                        }
                    })
                }
            },

            // 设置拖动排序
            getdata(evt) {
            },
            datadragEnd(evt) {
                console.log(evt);
            },
            // 关闭
            handleClose() {
                this.$emit('getTbList', this.tablelist, false);
            },
        },
    }
</script>

<style scoped>

</style>
