<template>
  <el-dialog title="导入专题库" :close-on-click-modal="false" append-to-body :visible.sync="visible">
    <div v-loading="importLoading" element-loading-text="数据导入中">
      <div v-if="!importLoading">
        <el-result icon="success" title="导入成功" :subTitle="'本次共导入'+importData.length+'条数据。'"
                   v-if="importData.length>0"></el-result>
        <el-result icon="error" title="导入失败" :subTitle="msg" v-else>
        </el-result>
        <!--                <div style="height: 150px;overflow-y: auto" v-if="importData.length===0">{{ msg }}</div>-->
        <!--                <div v-if="!importLoading && !importData.code" class="text_center">-->
        <!--                    <div v-for="(item, index) in importData.list" :key="index">{{item}}</div>-->
        <!--                </div>-->
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">关闭</el-button>
        </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      importLoading: true,
      importData: [],
      msg: "导入失败，请检查文件内容是否正确",
    }
  },
  methods: {
    init(param, url) {
      this.importLoading = true
      this.importData = []
      this.visible = true
      const formData = new FormData()
      formData.append('file', param.file)
      let feachUrl
      if (url) {
        feachUrl = url
      } else {
        feachUrl = this.api.original.thematiclibraryExcelImport
      }
      this.$axios(feachUrl, formData, 'post').then(data => {
        if (data.status) {
          this.importData = data.data.data
          this.$emit("refreshDataList")
        } else {
          this.msg = data.msg
        }
        this.importLoading = false
      })
    }
  },
}
</script>

<style scoped>

</style>
