<template>
  <el-dialog :title="`编辑${title}`" :modal-append-to-body="true"
             :append-to-body="true" id="detailsPage" :fullscreen="true" :close-on-click-modal="false"
             :visible.sync="visible"
             :before-close="close" v-if="visible">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="资源信息" name="0">
        <el-row>
          <el-col :span="18" class="b_d p_all1 p_r">
            <div :class="['showListBtn', showList ? 'el-icon-arrow-right':'el-icon-arrow-left']"
                 @click="showList = !showList" v-if="listType != 1"></div>
            <div class="modelBox text_center" v-if="dataList.length == 0">
              <img src="../../../../../../assets/img/upload.png" class="m_t2">
              <div class="m_t2">暂无资源信息哦~</div>
            </div>
            <div class="modelBox" v-else v-loading="loading">
              <div v-if="listType == 1 && uploadFileStatus" class="zhezhao"></div>
              <el-carousel :autoplay="false" ref="carouse" indicator-position="none" type="card"
                           :arrow="uploadFileStatus ? 'never' : 'hover'"
                           height="calc(100vh - 290px)" @change="changeImg"
                           v-if="listType == 1">
                <el-carousel-item v-for="(item, index) in dataList" :key="index">
                  <el-image :src="item.url" fit="contain" :preview-src-list="[item.url]">
                    <el-image slot="placeholder" fit="contain" :src="require('@/assets/img/collPhoto.png')"></el-image>
                    <el-image slot="error" fit="contain" :src="require('@/assets/img/collPhoto.png')"></el-image>
                  </el-image>
                </el-carousel-item>
              </el-carousel>
              <el-row :gutter="10" class="h100" v-else>
                <el-col :span="showList ? 17 : 23" class="h100">
                  <iframe id="frame" :src="this.$pdfPreviewUrl() + 'onlinePreview?url=' + fileUrl"
                          style="width: 100%;height: 100%;"></iframe>
                </el-col>
                <el-col v-show="showList" :span="6" class="videoList">
                  <el-table
                      :data="dataList"
                      size="small"
                      height="100%"
                      class="pointer" @row-click="clickRow">
                    <el-table-column prop="fileName" show-overflow-tooltip
                                     label="文件名称"></el-table-column>
                  </el-table>
                </el-col>
              </el-row>
            </div>
            <div class="flex_b_c">
              <div>
                <el-tag
                    v-for="(item, index) in dynamicTags" :key="item.id" v-if="index < 3"
                    :closable="hasPermission('resourceDetails:index:delete') || item.labelFlag"
                    :disable-transitions="false"
                    @close="handleClose(item, index)">
                  {{ item.labelName }}
                </el-tag>
                <el-popover v-if="dynamicTags.length > 3"
                            placement="right"
                            width="400"
                            trigger="click">
                  <el-tag
                      v-for="(item, index) in dynamicTags" :key="item.id" v-if="index > 2"
                      :closable="hasPermission('resourceDetails:index:delete') || item.labelFlag"
                      :disable-transitions="false"
                      @close="handleClose(item, index)">
                    {{ item.labelName }}
                  </el-tag>
                  <el-button slot="reference" size="small" class="moreBtn">...</el-button>
                </el-popover>
                <el-select class="input-new-tag" size="small"
                           v-model="inputValue" v-if="inputVisible"
                           filterable
                           allow-create
                           default-first-option @change="changeIpt">
                  <el-option
                      v-for="item in tagOpt"
                      :key="item.id"
                      :label="item.labelName"
                      :value="item.labelName">
                  </el-option>
                </el-select>
                <el-button v-if="dataList.length > 0" type="primary" plain class="button-new-tag"
                           size="small"
                           @click="showInput">
                  +添加标签
                </el-button>
              </div>
              <div class="flex_r_c">
                <el-button v-if="dataList.length && hasPermission('admin:resources:download')"
                           size="small" type="primary" plain
                           icon="el-icon-download" circle @click="downloadThree(info)"></el-button>
                <el-button v-if="dataList.length && hasPermission('resource:details:delFile')"
                           size="small" type="danger" plain
                           icon="el-icon-delete" circle
                           @click="del(info.id,info.fileName)"></el-button>
                <el-button size="small" type="primary" plain icon="el-icon-upload2"
                           v-show="hasPermission('resource:details:upload')"
                           @click="startUpdateFile(true)"
                           circle v-if="!uploadFileStatus"></el-button>
                <el-button size="small" v-if="uploadFileStatus" @click="startUpdateFile(false)">
                  取消上传
                </el-button>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <el-tabs type="border-card">
              <el-tab-pane label="文件详情" v-show="!(inputForm.id==null||inputForm.id=='')">
                <el-form size="small" :model="inputForm" ref="inputForm" label-width="130px"
                         v-if="listType == 1">
                  <el-form-item label="上传文件：" :rules="[{ required: true}]" v-if="uploadFileStatus">
                    <Upload :uploadAskObj="uploadRequire" @getDataList="updateFile"></Upload>
                  </el-form-item>
                  <el-form-item label="事由/题名：" prop="fileName" v-if="!uploadFileStatus" :rules="[{ required: true, message: '请输入事由/题名', trigger: 'blur' }]">
                    <el-input v-model.trim="inputForm.fileName" maxlength="60" placeholder="限60字"
                              clearable></el-input>
                  </el-form-item>
                  <el-form-item label="照片号/底片号：" prop="photoNumber">
                    <el-input v-model.trim="inputForm.photoNumber" maxlength="20" placeholder="限20字"
                              clearable></el-input>
                  </el-form-item>
                  <el-form-item label="时间：" prop="time">
                    <el-date-picker type="date" value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd" v-model="inputForm.time" placeholder="请选择"
                                    class="w100i"></el-date-picker>
                  </el-form-item>
                  <el-form-item label="年度：" prop="resourceYear">
                    <el-date-picker
                        value-format="yyyy"
                        format="yyyy"
                        v-model="inputForm.resourceYear"
                        type="year" placeholder="请选择" class="w100i">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="地点：" prop="place">
                    <el-input v-model.trim="inputForm.place" maxlength="20" placeholder="限20字"
                              clearable></el-input>
                  </el-form-item>
                  <el-form-item label="人物：" prop="resourceCharacter">
                    <el-input v-model.trim="inputForm.resourceCharacter" maxlength="20"
                              placeholder="限20字" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="背景：" prop="background">
                    <el-input v-model.trim="inputForm.background" maxlength="50" placeholder="限50字"
                              clearable></el-input>
                  </el-form-item>
                  <el-form-item label="拍摄者：" prop="photographer">
                    <el-input v-model.trim="inputForm.photographer" maxlength="20"
                              placeholder="限20字" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="作者：" prop="author">
                    <el-input v-model.trim="inputForm.author" maxlength="20"
                              placeholder="限20字" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="相册名：" prop="albumName">
                    <el-input v-model.trim="inputForm.albumName" maxlength="20" placeholder="限20字"
                              clearable></el-input>
                  </el-form-item>
                  <el-form-item label="主题词/关键词：" prop="keyword">
                    <el-input v-model.trim="inputForm.keyword" maxlength="20" placeholder="限20字"
                              clearable></el-input>
                  </el-form-item>
                  <el-form-item label="密级：" prop="classificationLevel">
                    <el-input v-model.trim="inputForm.classificationLevel" maxlength="10"
                              placeholder="限10字" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="保管期限：" prop="storagePeriod">
                    <el-input v-model.trim="inputForm.storagePeriod" maxlength="3"
                              placeholder="如永久或10年" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="类型规格：" prop="typeSpecifications">
                    <el-input v-model.trim="inputForm.typeSpecifications" maxlength="10"
                              placeholder="限10字" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="备注：" prop="remarks">
                    <el-input v-model.trim="inputForm.remarks" maxlength="200" placeholder="限200字"
                              clearable></el-input>
                  </el-form-item>
                </el-form>
                <el-form size="small" :model="inputForm" ref="inputForm" label-width="100px" v-else>
                  <el-form-item label="文件名称：" prop="fileName" v-if="!uploadFileStatus" :rules="[{ required: true, message: '请输入文件名称', trigger: 'blur' }]">
                    <el-input v-model.trim="inputForm.fileName" maxlength="60" placeholder="限60字"
                              clearable></el-input>
                  </el-form-item>
                  <el-form-item label="文件大小：" prop="fileSizeFormat" v-if="!uploadFileStatus">
                    <el-input v-model.trim="inputForm.fileSizeFormat" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="文件格式：" prop="fileFormat" v-if="!uploadFileStatus">
                    <el-input v-model="inputForm.fileFormat" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="上传文件：" :rules="[{ required: true}]" v-if="uploadFileStatus">
                    <Upload :uploadAskObj="uploadRequire" @getDataList="updateFile"></Upload>
                  </el-form-item>
                  <el-form-item label="文件描述：" prop="fileDesc">
                    <el-input v-model.trim="inputForm.fileDesc" placeholder="限20字" maxlength="20"
                              clearable></el-input>
                  </el-form-item>
                  <el-form-item label="配音" prop="photographed" v-if="listType == 2">
                    <el-input v-model="inputForm.photographed" placeholder="限100字" maxlength="100"
                              clearable></el-input>
                  </el-form-item>
                  <el-form-item label="出镜" prop="impersonator" v-if="listType == 3">
                    <el-input v-model="inputForm.impersonator" placeholder="限100字" maxlength="100"
                              clearable></el-input>
                  </el-form-item>
                  <el-form-item label="文案" prop="copywriting" v-if="listType == 2 || listType == 3">
                    <el-input v-model="inputForm.copywriting" placeholder="限1000字" maxlength="1000"
                              clearable></el-input>
                  </el-form-item>
                  <el-form-item label="撰稿" prop="contribution" v-if="listType == 2 || listType == 3">
                    <el-input v-model="inputForm.contribution" placeholder="限100字" maxlength="100"
                              clearable></el-input>
                  </el-form-item>
                  <el-form-item label="拍摄人：" prop="shotUser" v-if="listType == 3">
                    <el-input v-model.trim="inputForm.shotUser" placeholder="限20字" maxlength="20"
                              clearable></el-input>
                  </el-form-item>
                  <el-form-item label="拍摄日期：" prop="shotTime" v-if="listType == 3">
                    <el-date-picker class="w100i"
                                    value-format="yyyy-MM-dd"
                                    v-model="inputForm.shotTime"
                                    type="date"
                                    format=""
                                    placeholder="选择日期" clearable>
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="拍摄任务：" prop="shotTask" v-if="listType == 3">
                    <el-input v-model.trim="inputForm.shotTask" maxlength="80" placeholder="限80字"
                              clearable></el-input>
                  </el-form-item>
                  <el-form-item label="拍摄地点：" prop="shotAddress" v-if="listType == 3">
                    <el-input v-model.trim="inputForm.shotAddress" maxlength="20" placeholder="限20字"
                              clearable></el-input>
                  </el-form-item>
                  <el-form-item label="拍摄设备：" prop="shotDevice" v-if="listType == 3">
                    <el-input v-model.trim="inputForm.shotDevice" maxlength="80" placeholder="限80字"
                              clearable></el-input>
                  </el-form-item>
                </el-form>
              </el-tab-pane>
              <el-tab-pane label="资源详情">
                <el-form size="small" :model="detailsForm" ref="detailsForm" label-width="120px">
                  <el-form-item label="缩略图：" prop="thumbPath">
                    <div class="flex_l_t">
                      <div
                          v-for="(item,index) in upDateList" :key="index"
                          style="position: relative;padding-bottom: 10px;width: 110px;margin-right: 15px"
                      >
                        <div class="el-icon-error" @click="deleteFile(item,index,'beforeProcessingList')"
                             style="position:absolute; top: -8px;right: -8px;font-size: 16px;z-index: 99;cursor: pointer"></div>
                        <el-image
                            style="width: 100%; height: 110px;"
                            :src="item.netUrl"
                            :fit="'cover'"
                            :preview-src-list="[item.netUrl]"
                        >
                          <div slot="error" class="image-slot">
                            <el-image
                                style="width: 100%; height: 110px;"
                                :src="require('@/assets/img/default.png')"
                                :fit="'cover'">
                            </el-image>
                          </div>
                        </el-image>
                        <el-tooltip class="item" effect="dark" :content="item.fileName" placement="top">
                          <div style="font-size: 12px;width: 100%;margin-top: -18px;" class="omit">{{
                              item.fileName
                            }}
                          </div>
                        </el-tooltip>
                      </div>
                      <upload v-show="upDateList.length == 0" :uploadAskObj="uploadRequire2" @getDataList="getDataList">
                        <template v-slot:updateStyle>
                          <div style="width: 100px;
                          text-align: center;
                          height: 100px;
                          line-height: 100px;
                          border: 1px dashed #d9d9d9;
                          border-radius: 6px;
                          ">
                            <div class="el-icon-plus" style="
                            font-size: 20px;
                              "></div>
                          </div>
                        </template>
                      </upload>
                    </div>

                  </el-form-item>
                  <el-form-item label="资源类型：">{{ title }}</el-form-item>
                  <el-form-item label="类型编码：">{{ detailsForm.resourceTypeCode }}</el-form-item>
                  <el-form-item label="资源简称：" prop="resourceFoshot">
                    <el-input v-model.trim="detailsForm.resourceFoshot" maxlength="100"
                              placeholder="请输入资源简称（100字内）" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="是否藏品：" prop="onCollection">
                    <el-switch v-model="detailsForm.onCollection" @change="setColl"
                               :active-value="1" :inactive-value="0"
                    ></el-switch>
                  </el-form-item>
                  <el-form-item label="资源描述：" prop="resourceDesc">
                    <el-input
                        type="textarea"
                        placeholder="请输入资源描述（100字内）"
                        v-model.trim="detailsForm.resourceDesc"
                        maxlength="100" :autosize="{ minRows: 3, maxRows: 6}"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="资源名称：" prop="resourceName"
                                :rules="[{required: true, message: '请输入资源名称', trigger: 'change'}]">
                    <el-input v-model.trim="detailsForm.resourceName" maxlength="100"
                              placeholder="请输入资源名称（100字内）"></el-input>
                  </el-form-item>

                  <el-form-item v-for="item in dynamicForm" :key="item.id" :label="item.fieldName"
                                :prop=item.value>
                    <el-date-picker
                        value-format="yyyy-MM-dd"
                        v-model="detailsForm[item.fieldEname]"
                        type="date"
                        format="yyyy-MM-dd"
                        placeholder="选择日期" v-if="item.detailsShowType == 3" class="w100i">
                    </el-date-picker>
                    <el-input v-else-if="item.fieldEname == 'resourceTitle'"
                              v-model.trim="detailsForm[item.fieldEname]" maxlength="500" placeholder="请输入（限500字）"></el-input>
                    <el-input maxlength="20" v-else
                              v-model.trim="detailsForm[item.fieldEname]" placeholder="请输入（限20字）"
                              clearable></el-input>
                  </el-form-item>
                </el-form>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="关联藏品" :disabled="!detailsForm.onCollection" name="1">
        <el-row :gutter="10" class="m_b2">
          <div class="flex_l_c">
            <el-button type="primary" @click="relatedCollections" :disabled="collectioList.length != 0"
                       style="margin-bottom: 10px" size="small">获取藏品
            </el-button>
          </div>
          <div class="flex_l_c p_t15">
            <div :style="{background: defaultTheme}" class="titLine"></div>
            <div class="f_w">已关联藏品</div>
          </div>
        </el-row>

        <el-table
            :data="collectioList"
            size="small"
            height="calc(100vh - 320px)"
            class="table">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="collectionName" label="藏品名称"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="oneTypeName" label="藏品分类" show-overflow-tooltip></el-table-column>
          <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.identified === '0' ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column prop="collectionLevel" label="级别" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getLeaveList(scope.row.collectionLevel) }}
            </template>
          </el-table-column>
          <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
          <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
          <el-table-column
              label="操作">
            <template slot-scope="scope">
              <el-button
                  size="small" type="text"
                  v-show="hasPermission('admin:resources:cancelCollection')"
                  @click="bindCollection()">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="资源日志" name="2">
        <el-row :gutter="10" class="m_b2">
          <el-col :span="7">
            <el-input size="small" @keyup.enter.native="refreshLogList(1)" v-model="logSrh" maxlength="20" placeholder="请输入文件名称关键词(限20字)" clearable></el-input>
          </el-col>
          <el-col :span="8">
            <el-button type="primary" @click="refreshLogList(1)" size="small" icon="el-icon-search">查询
            </el-button>
          </el-col>
        </el-row>
        <el-table
            :data="logList"
            size="small"
            height="calc(100vh - 300px)"
            class="table">
          <el-table-column
              prop="fileName"
              show-overflow-tooltip sortable
              label="文件名称">
          </el-table-column>
          <el-table-column
              prop="operationType"
              show-overflow-tooltip sortable
              label="操作类型">
          </el-table-column>
          <el-table-column
              prop="operationRes"
              show-overflow-tooltip sortable
              label="操作结果">
          </el-table-column>
          <el-table-column
              prop="updateUserName"
              show-overflow-tooltip sortable
              label="更新人">
          </el-table-column>
          <el-table-column
              prop="updateDate"
              show-overflow-tooltip sortable
              label="更新时间">
          </el-table-column>
          <el-table-column
              prop="updateIp"
              show-overflow-tooltip sortable
              label="更新人IP">
          </el-table-column>
        </el-table>
        <div class="text_center">
          <el-pagination
              @size-change="sizeChangeHandleLog"
              @current-change="currentChangeHandleLog"
              :current-page="pageNoLog"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSizeLog"
              :total="totalLog"
              background
              layout="total, sizes, prev, pager, next, jumper">
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
    <span slot="footer" v-if="activeName==0" class="dialog-footer">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" type="primary"
                       v-show="tableNum || hasPermission('admin:resources:resourceEdit')" @click="doSubmit()"
                       v-noMoreClick>保存</el-button>
        </span>
    <coll-list ref="collectionList"
               @seltDataColl="linkCollection"></coll-list>
  </el-dialog>
</template>

<script>
import Upload from "@/components/Upload/Upload";
import CollList from "@/views/modules/dyyg/gather/resource/collList.vue";
import {Base64} from 'js-base64'
import resumeUpload from "@/components/Upload/resumeUpload.vue";

export default {
  components: {resumeUpload, CollList, Upload},
  data() {
    return {
      loading: false,
      id: '',
      resourceType: 0,
      collectionId: '',
      moduleId: '',
      visible: false,
      listType: '',
      title: '',
      allTableByResource: [],//全部输入框
      dynamicForm: [],//动态显示输入框
      activeName: '0',
      fileList: [],
      //uploadUrl: this.$globalUrl() + this.api.uploadCommon.resourceUploadFile + '?id=' + this.id + "&resourceType=" + this.resourceType,
      // 上传文件
      myHeaders: {Authorization: sessionStorage.getItem('token'), satoken: sessionStorage.getItem('token')},
      // 资源信息
      dataList: [],
      pageNo: 1,
      pageSize: 9999,
      total: 0,
      info: {},
      fileUrl: '',
      fileTypeSuffix: '',//文件后缀
      // 图片上传要求
      uploadRequire: {
        list: [],
        showFile: true, // 是否显示已上传文件列表
        count: 1, // 上传文件数量 0表示不限制文件的数量
        UploadAddress: '',
        fileType: ['gif', 'jpg', 'jpeg', 'bmp', 'png', 'JPG', 'PNG'],
        listType: 'file',
        megaNum: 1024
      },
      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
      tagOpt: [],
      inputForm: {
        fileDesc: '',
        impersonator: '',
        photographed: '',
        copywriting: '',
        contribution: '',
        shotUser: '',
        shotTime: '',
        shotTask: '',
        shotAddress: '',
        shotDevice: '',
        describe: '',
        resourceWidth: '',
        resourceHeight: '',
        focalLength: '',
        shutter: '',
        iso: '',
        aperture: '',
        // 图片
        photoNumber: '',
        reason: '',
        time: '',
        resourceYear: '',
        place: '',
        resourceCharacter: '',
        background: '',
        photographer: '',
        author: '',
        albumName: '',
        keyword: '',
        classificationLevel: '',
        storagePeriod: '',
        typeSpecifications: '',
        operationField: '',
        remarks: '',
      },
      inputForm2: {},
      detailsForm: {},
      // 关联藏品
      collectionNum: '',
      collectionShow: false,
      collectioList: [],
      // 资源日志
      logSrh: '',
      logList: [],
      pageNoLog: 1,
      pageSizeLog: 10,
      totalLog: 0,
      uploadFileStatus: false, // 点击上传按钮
      uploadFilePath: '', // 点击上传地址
      uploadFileName: '', // 上次名称
      fileSize: '', // 上次名称
      fileSizeFormat: '', // 上次名称
      fileFormat: '', // 上次格式
      tableNum: true, // 当前类别
      showList: false,
      leaveList: [],

      // 缩略图上传要求
      uploadRequire2: {
        list:[],
        showFile: false, // 是否显示已上传文件列表
        count: 1, // 上传文件数量 0表示不限制文件的数量
        fileType: ['jpg', 'png', 'jpeg'],
        megaNum: 10,
        showTips: true,
        customUploadStyle: true,
      },
      upDateList: [],
      resourcesPreviewFileType: [], // 支持预览的文件格式
    }
  },
  computed: {
    // 主题色
    defaultTheme() {
      return this.$store.state.config.defaultTheme
    },
  },
  mounted() {
    this.$axios(this.api.collection.listLevelSelect).then(data => {
      if (data.status) {
        this.leaveList = data.data
      }
    })
  },
  methods: {
    //关联藏品
    relatedCollections() {
      this.$refs.collectionList.int(2, {
        resourcesId: this.id,
        resourceType: this.resourceType,
        onBind: 1,
      })
    },

    // 获取上传缩略图数据
    getDataList(data) {
      this.upDateList = [
        {
          netUrl:data.response.data.url,
          fileName:data.response.data.name,
        }
      ]
      this.detailsForm.fileName = data.response.data.name
      this.detailsForm.thumbPath = data.response.data.path
      // this.detailsForm.url = data.response.data.url
    },

    deleteFile(row, index) {
      this.$confirm('此操作将删除该缩略图, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.upDateList.splice(index, 1)
        this.uploadRequire2.list = []
        this.detailsForm.fileName = ''
        // this.detailsForm.url = ''
        this.detailsForm.thumbPath = ''
      })
    },

    //切换是否藏品
    setColl(value) {
      if (value == 0 && this.collectioList.length) {
        this.detailsForm.onCollection = 1
        this.$confirm(`是否删除藏品信息?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.detailsForm.onCollection = 0
          this.collectioList = []
        }).catch(() => {
          this.detailsForm.onCollection = 1
        })
      }
    },

    getLeaveList(leave) {
      let data = this.leaveList.filter(item => {
        return item.level == leave
      })
      if (data.length) {
        return data[0].levelName
      }
    },

    init(id, listType, moduleId, collectionId, resourceType) {
      this.collectionNum = ''
      this.logSrh = ''
      this.fileTypeSuffix = ''
      this.id = id
      this.info = {}
      this.moduleId = moduleId
      this.listType = listType
      this.collectionId = collectionId
      this.resourceType = resourceType
      this.title = this.$dictUtils.getDictLabel("resource_type", listType, '')
      this.resourcesPreviewFileType = this.getResourcePreviewFileFormat(listType)
      this.uploadRequire.UploadAddress = this.api.uploadCommon.resourceUploadFile + '?resourcesId=' + this.id + "&resourceType=" + this.resourceType + '&';
      this.uploadRequire.fileType = this.getResourceUploadFileFormat(listType)
      if (listType == 1) {
        this.uploadRequire.megaNum = 10
      } else {
        this.uploadRequire.megaNum = 1024
      }
      this.visible = true
      this.pageNo = 1
      this.pageNoLog = 1
      this.$nextTick(() => {
        this.activeName = '0';
        this.refreshList()
        this.queryLabelNoRe()
        this.queryLabelRe()
        this.refreshLogList();
      })

      // this.id = id;
      this.moduleId = moduleId;
    },

    // 水印
    getResourceFilesWatermark() {
      let first = this.info.url.lastIndexOf(".");
      let nameLength = this.info.url.length;
      let fileFormat = this.info.url.substring(first + 1, nameLength).toLowerCase();
      let watermarkFile = ['gif', 'jpg', 'jpeg', 'png', 'pdf']
      if (watermarkFile.indexOf(fileFormat) == -1) {
        return
      }
      this.loading = true
      this.$axios(this.api.digital.getResourceFilesWatermark, {
        id: this.info.id,
      }, 'get').then(res => {
        if (res.status && res.data) {
          if (res.data.url.indexOf('http') > -1) {
            this.$set(this.info, 'url', res.data.url)
            this.fileUrl = encodeURIComponent(Base64.encode(this.info.url)).replaceAll("\\+", "%20")
          }
        }
        setTimeout(() => {
          this.loading = false
        }, 1000)
      })
    },

    // 获取文件后缀识别文件类型
    getFileType(data) {
      let first = data.lastIndexOf(".");//取到文件名开始到最后一个点的长度
      let namelength = data.length;//取到文件名长度
      this.fileTypeSuffix = data.substring(first + 1, namelength);//截取获得后缀名
      if(this.resourcesPreviewFileType.indexOf(this.fileTypeSuffix) == -1) {
        this.$message.warning("该文件不支持预览，请下载后本地查看")
      }
    },

    handleClick(tab, event) {
      if (tab.index == 0) {
        this.tableNum = true
      } else {
        this.tableNum = false
      }
    },
    // 弹窗
    close() {
      this.tableNum = true
      this.inputVisible = false
      this.visible = false
      this.uploadFileStatus = false
      this.collectionShow = false
      this.$emit('refreshDataList')
    },

    // 关联藏品
    linkCollection(row) {
      this.$set(row, 'collectionId', row.id)
      this.$delete(row, 'id')
      this.collectioList = [row]
    },
    //获取元数据信息
    getTableByResourceName() {
      this.dynamicForm = [];
      this.$axios(this.api.rcPage.getTableByResourceName, {
        resourceName: '新增资源',
      }, 'get').then(res => {
        if (res.status) {
          this.allTableByResource = res.data.data;
          this.allTableByResource.map(item => {
            if (item.fieldEname != 'collectionId' && item.fieldEname != 'collection_id') {
              if (this.detailsForm[item.fieldEname] == null) {
                this.$set(this.detailsForm, item.fieldEname, "")
              } else {
                item.value = this.detailsForm[item.fieldEname];
              }
              this.dynamicForm.push(item);
            }
          })
        }
      })
    },

    // 资源信息列表数据查询
    refreshList(type) {
      this.$axios(this.api.rcPage.getResourcesInfo, {
        resourcesId: this.id,
        'current': this.pageNo,
        'size': this.pageSize,
      }, 'get').then(data => {
        if (data.status) {
          this.dataList = data.data.resourcesFileList.records;
          this.total = parseInt(data.data.resourcesFileList.total)
          if (this.dataList.length) {
            if(this.info.id && !type) {
              let index = this.dataList.findIndex(item => item.id == this.info.id)
              if(this.listType != 1) {
                this.clickRow(this.dataList[index])
              } else {
                this.changeImg(index)
              }
            } else {
              this.inputForm = this.dataList[0]
              this.info = this.dataList[0];
              this.fileUrl = encodeURIComponent(Base64.encode(this.info.url)).replaceAll("\\+", "%20")
              this.getFileType(this.info.url);
              this.getResourceFilesWatermark()
            }
          } else {
            Object.keys(this.inputForm).forEach(key => (this.inputForm[key] = ''))
            Object.keys(this.info).forEach(key => (this.info[key] = ''))
          }
          this.detailsForm = data.data.resourcesInfo;
          this.upDateList = [];
          if (this.detailsForm.url) {
            this.upDateList.push(
                {
                  fileName: this.detailsForm.fileName,
                  filePath: this.detailsForm.thumbPath,
                  netUrl: this.detailsForm.url,
                },
            )
          }
          if (data.data.resourcesInfo.collectionId) {
            this.collectioList = [data.data.resourcesInfo]
          } else {
            this.collectioList = []
          }
          this.getTableByResourceName();
        }
      })
    },
    startUpdateFile(val) {
      if (val) {
        this.uploadFileStatus = true
        this.inputForm2 = this.inputForm
        this.inputForm = {}
        this.inputForm.id = '0'
      } else {
        this.uploadFileStatus = false
        this.inputForm = this.inputForm2
      }
    },
    // 获取上传图片数据
    updateFile(data) {
      if (data.response) {
        this.uploadFilePath = data.response.data.url
        this.uploadFileName = data.response.data.name
        this.fileSize = data.response.data.fileSize
        this.fileSizeFormat = data.response.data.fileSizeFormat
        this.fileFormat = this.uploadFileName.substring(this.uploadFileName.lastIndexOf('.') + 1)
      }
    },
    downloadThree(info) {
      if (info == null || info.url == null) {
        this.$message.warning("暂无资源！")
        return
      }
      // 发送日志
      this.$axios(this.api.rcPage.saveFileLog, {
        'fileId': info.id,
        'operationType': '下载文件',
        'resourceId': this.id,
        'fileName': info.fileName,
      }, 'post').then(data => {
        if (data.status) {
          this.refreshLogList();
        }
      })
      const a = document.createElement('a')
      const url = this.info.url
      fetch(url).then(res => res.blob()).then(blob => {
        a.href = URL.createObjectURL(blob)
        a.download = info.fileName;
        document.body.appendChild(a)
        a.click();
      })
      this.refreshLogList()
    },
    // 图片切换
    changeImg(val) {
      this.info = this.dataList[val];
      this.inputForm = this.dataList[val]
      this.getFileType(this.info.url);
      this.getResourceFilesWatermark()
    },
    // 点击表格行
    clickRow(val) {
      this.info = val;
      this.inputForm = val
      this.fileUrl = encodeURIComponent(Base64.encode(this.info.url)).replaceAll("\\+", "%20")
      this.getFileType(this.info.url);
      this.getResourceFilesWatermark()
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageNo = 1
      this.refreshList()

    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val
      this.refreshList()
    },
    // 关闭标签
    handleClose(item, index) {
      this.$axios(`${this.api.rcPage.queryLabelRemove}/${item.id}`, {}, 'Delete').then(res => {
        if (res.status) {
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
        this.queryLabelRe()
      })
    },
    // 显示添加标签输入框
    showInput() {
      this.inputVisible = true;
    },
    // 查询所有标签
    queryLabelNoRe() {
      this.$axios(this.api.rcPage.queryLabelNoRe, {
        'resourceId': this.id,
      }, 'get').then(res => {
        if (res.status) {
          this.tagOpt = res.data
        }
      })
    },
    // 查询当前资源标签
    queryLabelRe() {
      this.$axios(this.api.rcPage.queryLabelRe, {
        'resourceId': this.id,
      }, 'get').then(res => {
        if (res.status) {
          this.dynamicTags = res.data;
        }
      })
    },
    changeIpt(val) {
      if (val.trim().length == 0) {
        this.$message('请输入标签内容')
        return false
      }
      this.$axios(this.api.rcPage.saveLabel, {
        'lableName': val,
        'resourceId': this.id,
        'labelType': this.title,
      }, 'post').then(res => {
        if (res.status) {
          this.$message.success(res.msg)
          this.queryLabelNoRe()
          this.queryLabelRe()
        } else {
          this.$message.error(res.msg)
        }
      })
      this.inputValue = '';
      this.inputVisible = false;
    },
    // 删除 type: 1资源信息删除
    del(id, fileName) {
      if (!id) {
        this.$message.warning("暂无资源！")
        return
      }
      if (this.dataList.length == 1) {
        this.$message.warning("最少保留一条数据")
        return
      }
      let index = this.dataList.findIndex(item => item.id == id)
      this.$confirm(`确定删除所选项吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除文件
        this.$axios(this.api.rcPage.resourceFileUpdateById, {
          'id': id,
          'delFlag': 1
        }, 'post').then(data => {
          if (data && data.status) {
            this.$message.success('删除成功')
            this.dataList.splice(index, 1)
            if(index > 0) {
              index--
            }
            if(this.listType != 1) {
              this.clickRow(this.dataList[index])
            } else {
              this.changeImg(index)
            }
          } else {
            this.$message.error(data.msg)
          }
        })
        // 发送日志
        this.$axios(this.api.rcPage.saveFileLog, {
          'fileId': id,
          'operationType': '删除文件',
          'operationRes': '执行成功',
          'resourceId': this.id,
          'fileName': fileName,
        }, 'post').then(data => {
          if (data.status) {
            this.refreshLogList();
          }
        })
      })
    },
    /* 资源绑定产品 */
    bindCollection() {
      this.$confirm(`确定删除所选项吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.collectioList = []
      })
    },
    // 资源日志列表数据查询
    refreshLogList(type) {
      if (type == 1) {
        this.pageNoLog = 1
      }
      this.$axios(this.api.rcPage.getResourcesInfoRecode, {
        'resourcesId': this.id,
        'current': this.pageNoLog,
        'size': this.pageSizeLog,
        'fileName': this.logSrh,
      }, 'get').then(data => {
        if (data && data.status) {
          this.logList = data.data.records
          this.totalLog = parseInt(data.data.total)
        }
      })
    },
    // 资源信息每页数
    sizeChangeHandleLog(val) {
      this.pageSizeLog = val
      this.pageNoLog = 1
      this.refreshLogList()
    },
    // 资源信息当前页
    currentChangeHandleLog(val) {
      this.pageNoLog = val
      this.refreshLogList()
    },
    // 保存
    doSubmit() {
      if (!this.uploadFileStatus) {
        if (this.detailsForm.resourceName.length == 0 || this.detailsForm.resourceName.split(" ").join("").length == 0) {
          this.$message.warning("请输入资源名称")
          return
        }

        if (this.detailsForm.onCollection && !this.collectioList.length) {
          this.$message.warning("请关联藏品信息！")
          return
        }
        this.$refs['inputForm'].validate((valid) => {
          if (valid) {
            // 修改资源
            let updateResources = this.$axios(this.api.rcPage.resourcesUpdateById, {
              ...this.detailsForm,
              ...this.collectioList[0],
            }, 'post');
            let updateFile = true;
            if (this.inputForm.id != null) {
              // 修改文件
              updateFile = this.$axios(this.api.rcPage.resourceFileUpdate, this.dataList, 'post');
            }
            Promise.all([updateResources, updateFile]).then((result) => {
              if (result[0].status && (result[1] == true || result[1].status == true)) {
                this.$message.success("修改成功")
                this.refreshLogList()
                this.refreshList()
                this.uploadFileStatus = false
              } else {
                this.$message.error("修改失败")
              }
            })
          }
        })
      } else {
        // 新增文件
        if (this.uploadFilePath == null || this.uploadFilePath == '') {
          this.$message.warning("请上传文件")
          return
        }
        this.$set(this.inputForm, "url", this.uploadFilePath)
        this.$set(this.inputForm, "uploadTarget", this.uploadFilePath)
        this.$set(this.inputForm, "fileName", this.uploadFileName)
        this.$set(this.inputForm, "resourceId", this.id)
        this.$set(this.inputForm, "fileSizeFormat", this.fileSizeFormat)
        this.$set(this.inputForm, "fileFormat", this.fileFormat)
        this.$set(this.inputForm, "fileSize", this.fileSize)

        // 上传文件
        this.$axios(this.api.rcPage.resourceFileSave, this.inputForm, 'post').then(data => {
          if (data && data.status) {
            this.$message.success("新增成功")
            this.uploadFilePath = ''
            this.uploadFileStatus = false
            this.refreshLogList()
            this.refreshList(data.data)
          }
        });

      }
    }
  }
}
</script>

<style scoped>
.audioBox {
  height: 100%;
  overflow: auto;
}

#detailsPage .modelBox {
  position: relative;
  overflow: hidden;
  height: calc(100vh - 266px);
}

.zhezhao {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.el-carousel__item >>> .el-image {
  height: 100%;
  width: 100%;
}

.el-tabs--border-card >>> .el-tabs__content {
  height: calc(100vh - 237px);
  overflow-y: auto;
}

.el-tabs--border-card >>> .el-tabs__content::-webkit-scrollbar {
  display: none;
}

.el-tag + .el-tag, .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 150px;
  margin-left: 10px;
}

.word-wrap, .xlsx-wrap {
  overflow: auto;
}

.word-wrap > > > img, .xlsx-wrap > > > table {
  width: 100% !important;
}

.xlsx-wrap > > > tr {
  border-bottom: 1px solid #DCDCDC;
}

.titLine {
  width: 4px;
  height: 15px;
  margin-right: 8px;
}

.xlsx-wrap > > > td {
  padding: 6px;
  text-align: center;
}

.showListBtn {
  right: 0rem;
}

.imgUpData {
  width: 110px;
}
</style>
